import { useState, useCallback, useMemo } from 'react';
import { NetworkStatus } from '@apollo/client';
import fp from 'lodash/fp';
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useQuery, useMutation } from '<src>/apollo/client';

import { GetCredentials } from '<sections>/account/queries';

import dateMacros from '../dateMacros';

import {
  GetTablesetTables,
  AddTableToNumbrzDatabase,
  UpdateTable,
  DeleteTable,
  GetTableset,
  UpdateTableset,
  UpdateTableColumn,
  UpdateTableColumns,
  AddTableColumn,
  DeleteTableColumn,
  TruncateDataTable,
  SyncTableColumns,
  GetTablesetExternalTables,
  LinkTableToTableset,
} from '../../../queries';
import { formatParamValToPB } from '<sections>/data/utils';

function externalTableID(table) {
  switch (table.source.__typename) {
    case 'GoogleSheet':
      return table.source.sheetID;
    case 'ExcelSheet':
      return table.source.worksheetID;
    case 'FileSource':
      return table.source.name;
    case 'QuickbooksSource':
      return table.name;
    case 'UnknownRowSource':
      return table.ID;
    default:
      return table.source.tableName;
  }
}

export const getQBTableTitle = (tableName, dateMacro, toDate, fromDate) => {
  if (dateMacro && dateMacro !== 'custom') {
    const dateMacroLabel = dateMacros.find((dM) => dM.value === dateMacro).text;
    return `${tableName} - ${dateMacroLabel}`;
  }
  if (fromDate) {
    const fromDateLabel = moment(fromDate).format('MMM DD, YYYY');
    let toDateLabel = 'Today';
    if (toDate) toDateLabel = moment(toDate).format('MMM DD, YYYY');

    return `${tableName} - ${fromDateLabel} to ${toDateLabel}`;
  }

  return tableName;
};

export default function useTablesetState({ tablesetID }) {
  const history = useHistory();
  const match = useRouteMatch();
  const [activeTableID, setActiveTableID] = useState();
  const [deleting, setDeleting] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [dateMacro, setDateMacro] = useState(null);

  const { data: { tableset = {} } = {}, loading: loadingTableset } = useQuery(
    GetTableset,
    {
      variables: { ID: tablesetID },
    }
  );

  const {
    data: { listCredentials: credentials = [] } = {},
    loading: loadingCredentials,
  } = useQuery(GetCredentials, { variables: {} });

  const {
    data: { tableset: tablesetTables } = {},
    loading: loadingTables,
    refetch: refetchTables,
  } = useQuery(GetTablesetTables, {
    variables: { ID: tablesetID },
  });
  const tables = fp.getOr([], 'tables', tablesetTables);

  const [updateTableset] = useMutation(UpdateTableset);

  const sourceType = fp.getOr(undefined, 'source.__typename', tableset);

  const {
    data: { tableset: tablesetExt } = {},
    loading: loadingExternal,
    refetch: refetchExt,
    networkStatus,
  } = useQuery(GetTablesetExternalTables, {
    variables: { ID: tablesetID },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const extLoading = loadingExternal || networkStatus !== NetworkStatus.ready;
  const extTablesReq = fp.getOr([], 'externalTables.tables', tablesetExt);
  const extTables = extTablesReq
    .map((table) => ({ ...table, ID: externalTableID(table) }))
    .filter(
      (extTable) =>
        !fp
          .getOr([], 'tables', tablesetExt)
          .find(
            (table) =>
              extTable.source.__typename !== 'QuickbooksSource' &&
              extTable.ID === externalTableID(table)
          )
    );
  const extTableErr = fp.getOr(undefined, 'externalTables.err', tablesetExt);

  const activeTable = tables.find((table) => table.ID === activeTableID);
  let activeTableSource;
  if (activeTableID)
    activeTableSource = fp.getOr('', 'source.__typename', activeTable);

  const [addTableToNumbrzDatabase] = useMutation(AddTableToNumbrzDatabase, {
    update: (cache, { data: { addTableToNumbrzDatabase } }) => {
      const { tableset } = cache.readQuery({
        query: GetTablesetTables,
        variables: { ID: tablesetID },
      });

      cache.writeQuery({
        query: GetTablesetTables,
        variables: { ID: tablesetID },
        data: {
          tableset: {
            ...tableset,
            tables: [...tableset.tables].concat(addTableToNumbrzDatabase),
          },
        },
      });
    },
  });

  const navigateToTable = (e, data, isNew = false, match) => {
    setActiveTableID(data.value);
    history.push(`${match.url}/${data.value}${isNew ? '?new' : ''}`);
  };

  const navigateToPage = (e, data) => {
    history.push(`/data/${tablesetID}/${data.value}`);
  };

  const handleAddNumbrzTable = async () => {
    const payload = {
      variables: {
        tablesetID,
        name: 'Numbrz Table',
      },
    };

    const res = await addTableToNumbrzDatabase(payload);

    navigateToTable({}, { value: res.data.addTableToNumbrzDatabase.ID }, true, {
      url: `${match.url}/tables`,
    });
  };

  // Connected table methods

  const [onDelete] = useMutation(DeleteTable, {
    update: (cache, { data: { deleteDataTable: deleteTableID } }) => {
      const { tableset } = cache.readQuery({
        query: GetTablesetTables,
        variables: { ID: tablesetID },
      });
      if (deleteTableID) {
        const newTableset = {
          ...tableset,
          tables: tableset.tables.filter((t) => t.ID !== deleteTableID),
        };
        cache.writeQuery({
          query: GetTablesetTables,
          variables: { ID: tablesetID },
          data: { tableset: newTableset },
        });
        setActiveTableID(null);
      }
    },
  });

  const [updateTable] = useMutation(UpdateTable);
  const [updateColumn] = useMutation(UpdateTableColumn);
  const [updateColumns] = useMutation(UpdateTableColumns);
  const [addColumn] = useMutation(AddTableColumn);
  const [deleteColumn] = useMutation(DeleteTableColumn);
  const [syncColumns] = useMutation(SyncTableColumns);
  const [truncateTable] = useMutation(TruncateDataTable);

  const onTablesetFieldChange = useCallback(
    (field, value) => {
      return updateTableset({
        variables: { input: { ID: tablesetID, [field]: value } },
      });
    },
    [tablesetID, updateTableset]
  );

  const onUpdate = (field, value, table) => {
    if (table[field] !== value) {
      return updateTable({
        variables: { input: { ID: table.ID, [field]: value } },
      });
    }
  };

  const onUpdateColumn = (columnID, field, value, table) => {
    if (table[field] !== value || value === '') {
      return updateColumn({
        variables: {
          input: {
            tableID: table.ID,
            columnID,
            [field]: value,
            source: activeTableSource,
          },
        },
      });
    }
  };

  const onUpdateColumns = (columns, tableID, source) => {
    return updateColumns({
      variables: {
        input: { tableID, columns, source },
      },
    });
  };

  const onAddColumn = (label, type, tableID, source) => {
    return addColumn({
      variables: {
        tableID,
        label,
        type,
        source,
      },
    });
  };

  const onDeleteColumn = (columnID, tableID, source) => {
    return deleteColumn({
      variables: {
        tableID,
        columnID,
        source,
      },
    });
  };

  const onSyncColumns = (tableID) => {
    return syncColumns({
      variables: { ID: tableID },
    });
  };

  const onTruncate = (tableID) => truncateTable({ variables: { ID: tableID } });

  // External table Methods
  const [linkTableToTableset] = useMutation(LinkTableToTableset);

  const paneMutation = useMemo(() => {
    return {
      msexcel: {
        mutation: linkTableToTableset,
        linkResultField: 'linkTableToTableset',
        sourceIDField: 'worksheetID',
      },
      gsheets: {
        mutation: linkTableToTableset,
        linkResultField: 'linkTableToTableset',
        sourceIDField: 'sheetID',
      },
      bigquery: {
        mutation: linkTableToTableset,
        linkResultField: 'linkTableToTableset',
        sourceIDField: 'ID',
      },
      snowflake: {
        mutation: linkTableToTableset,
        linkResultField: 'linkTableToTableset',
        sourceIDField: 'tableName',
      },
      files: {
        mutation: linkTableToTableset,
        linkResultField: 'linkTableToTableset',
        sourceIDField: 'name',
      },
      quickbooks: {
        mutation: linkTableToTableset,
        linkResultField: 'linkTableToTableset',
        sourceIDField: 'entityID',
      },
      motherduck: {
        mutation: linkTableToTableset,
        linkResultField: 'linkTableToTableset',
        sourceIDField: 'name',
      },
    };
  }, [linkTableToTableset]);

  const linkTable = useCallback(
    (variables, table) => {
      const CompMutation = paneMutation[tableset.connector.name];

      return linkTableToTableset({
        variables,
        update: (cache, { data }) => {
          if (
            data[CompMutation['linkResultField']] &&
            data[CompMutation['linkResultField']].source.__typename !==
              'QuickbooksSource'
          ) {
            const { tableset } = cache.readQuery({
              query: GetTablesetExternalTables,
              variables: { ID: tablesetID },
            });
            const newTableset = {
              ...tableset,
              externalTables: tableset.externalTables.tables.filter(
                (et) =>
                  et.source[CompMutation['sourceIDField']] !==
                  variables[CompMutation['sourceIDField']]
              ),
            };

            cache.writeQuery({
              query: GetTablesetExternalTables,
              variables: { ID: tablesetID },
              data: { tableset: newTableset },
            });
          }
        },
      });
    },
    [paneMutation, tablesetID, tableset, linkTableToTableset]
  );

  const isGSheet = useMemo(() => {
    return tableset.connector?.name === 'gsheets';
  }, [tableset]);

  const isBigQueryTable = useMemo(() => {
    return tableset.connector?.name === 'bigquery';
  }, [tableset]);

  const isExcelSheet = useMemo(() => {
    return tableset.connector?.name === 'msexcel';
  }, [tableset]);

  const isFile = useMemo(() => {
    return tableset.connector?.name === 'files';
  }, [tableset]);

  const isQB = useMemo(() => {
    return tableset.connector?.name === 'quickbooks';
  }, [tableset]);

  const requiresDateInput = useCallback(
    (table) => {
      if (!table) return false;
      const { source } = table;

      const tableDateInputMap = {
        AccountList: false,
        ClassObject: false,
        DepartmentObject: false,
        PreferencesObject: false,
        ItemObject: false,
        TrialBalance: true,
        TrialBalanceByMonth: true,
        GeneralLedger: true,
        CashFlow: true,
        MonthlyCashFlow: true,
        TransactionList: true,
        InvoiceHeaderObject: false,
        EmployeeObject: false,
        VendorObject: false,
        SalesByProduct: true,
        APAgingDetail: false,
        ARAgingDetail: false,
      };
      if (isQB && source) {
        if (source.entityID && source.entityID.includes('Object')) return false;
        return tableDateInputMap[source.entityID];
      }

      return false;
    },
    [isQB]
  );

  const onConnect = useCallback(
    async (table, dateMacro, fromDate, toDate) => {
      const payload = {
        tablesetID,
        name: isQB
          ? getQBTableTitle(table.name, dateMacro, toDate, fromDate)
          : table.name,
        link: {
          tableID: isQB ? table.source.entityID : table.ID,
          params: table.linkParams.map((lP) => {
            const paramVal = table.paramValues.find(
              (pV) => pV.name === lP.name
            );
            if (paramVal) {
              return {
                ...paramVal,
                val: formatParamValToPB(lP.dataType, paramVal.val),
              };
            } else {
              if (lP.name === 'dateMacro' && dateMacro)
                return {
                  name: lP.name,
                  val: formatParamValToPB(lP.dataType, dateMacro),
                };
              if (lP.name === 'fromDate' && fromDate && dateMacro === 'custom')
                return {
                  name: lP.name,
                  val: formatParamValToPB(
                    lP.dataType,
                    moment(fromDate).format('x')
                  ),
                };
              if (lP.name === 'toDate' && fromDate && dateMacro === 'custom')
                return {
                  name: lP.name,
                  val: formatParamValToPB(
                    lP.dataType,
                    toDate ? moment(toDate).format('x') : null
                  ),
                };
            }
            return { name: lP.name, val: { isNull: true } };
          }),
        },
      };

      await linkTable(payload, table);
      await refetchTables();
    },
    [isQB, linkTable, refetchTables, tablesetID]
  );

  const isValid = useCallback(
    (table) => {
      if (isQB) {
        if (
          requiresDateInput(table) &&
          (!(dateMacro || fromDate) ||
            (dateMacro && dateMacro === 'custom' && !fromDate))
        )
          return false;
      }

      return true;
    },
    [isQB, requiresDateInput, dateMacro, fromDate]
  );

  const isValidNew = useCallback(
    (table, dateMacro, fromDate) => {
      if (isQB) {
        if (
          requiresDateInput(table) &&
          (!(dateMacro || fromDate) ||
            (dateMacro && dateMacro === 'custom' && !fromDate))
        )
          return false;
      }

      return true;
    },
    [isQB, requiresDateInput]
  );

  // End of External table methods
  const state = {
    tablesetType: sourceType,
    activeTable,
    isGSheet,
    isBigQueryTable,
    isExcelSheet,
    isFile,
    isQB,
    requiresDateInput,
    activeTableID,
    setActiveTableID,
    deleting,
    setDeleting,
    connecting,
    setConnecting,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    dateMacro,
    setDateMacro,
    updateTableset,
    onTablesetFieldChange,
    tableset,
    tables,
    loadingTables,
    refetchTables,
    extTables,
    extTableErr,
    refetchExt,
    extLoading,
    loadingTableset,
    handleAddNumbrzTable,
    navigateToTable,
    navigateToPage,
    extTableAPI: {
      linkTable,
      linkTableToTableset,
      onConnect,
      isValid,
      isValidNew,
    },
    tableAPI: {
      onUpdate,
      updateTable,
      onUpdateColumn,
      onUpdateColumns,
      onAddColumn,
      onDeleteColumn,
      onSyncColumns,
      onTruncate,
      onDelete,
    },
    credentials,
    loadingCredentials,
  };

  return state;
}
