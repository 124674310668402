import React, { PureComponent } from 'react';
import memoize from 'lodash/memoize';

import { sortBy } from '<src>/utils/text';

import NestedPickerPane from '<components>/NestedPickerPane';
import WaveSpinner from '<components>/WaveSpinner';

import TablesetDetail from './TablesetDetail';
import { DataSourceHdr } from '../NumbrzPageComponents';
import { formatDataSourceLabel } from '<sections>/data/utils';

const makeOptions = memoize((tablesets) => {
  const options = tablesets
    .map((ts) => ({
      name: ts.name,
      value: ts.ID,
      detail: { ...ts, tables: [...ts.tables] },
      connectorName: ts?.connector?.name,
    }))
    .filter((op) => op.detail.tables.length > 0);

  options.forEach((ts) => sortBy('name', ts.detail.tables));
  return options;
});

export default class TablesetPickerPane extends PureComponent {
  static defaultProps = {
    onChange: () => {},
  };

  render() {
    const { dialog, loading, tablesets, selectedID, onChange } = this.props;

    if (loading) return <WaveSpinner />;

    return (
      <NestedPickerPane
        selectMode="header"
        dialog={dialog}
        options={makeOptions(tablesets)}
        value={selectedID}
        onChange={(selectedID) => onChange(selectedID)}
        renderListItem={(item, isSubItem) => (
          <DataSourceHdr>
            {item.name}

            <span>
              {formatDataSourceLabel(
                item.detail.source.__typename,
                item?.detail?.connector?.name
              )}
            </span>
          </DataSourceHdr>
        )}
        renderItemDetail={(item) =>
          item ? (
            <TablesetDetail tableset={item.detail} />
          ) : (
            'Select an item on the left'
          )
        }
      />
    );
  }
}
