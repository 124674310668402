import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { Input } from 'semantic-ui-react';
import EditableMarkdown from '<components>/EditableMarkdown';

import { isEmpty } from '<src>/utils/text';

const StyledInput = styled(Input)`
  width: 100%;
  margin-top: 15px;
  & div.label {
    width: 25%;
  }
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export default function MotherDuckPane({
  onTablesetCreated,
  children: renderBody,
  createCredential,
  credential,
}) {
  const [data, setData] = useState();
  const [keyName, setKeyName] = useState();
  const [connecting, setConnecting] = useState(false);

  const createNewCredential = useCallback(async () => {
    // if credential exists, then we are reauthorizing
    setConnecting(true);
    await createCredential({
      variables: {
        input: {
          ...(!credential && { name: `MotherDuck` }),
          ...(credential && { ID: credential.ID }),
          provider: {
            providerName: 'motherDuck',
            credName: 'svcAccount',
            credParams: {
              data,
              keyName,
            },
          },
        },
      },
    });
    setConnecting(false);
  }, [createCredential, data, keyName, credential]);

  const valid = () => {
    return !isEmpty(data);
  };

  return renderBody(
    <div>
      <EditableMarkdown
        maxHeight="300px"
        label={'Access Token'}
        content={data}
        editable={true}
        onSave={(data) => setData(data)}
        placeholder={'Enter the Access Token (*Required)'}
      />
      <StyledInput
        size="small"
        label="Key name"
        name="keyName"
        placeholder="Enter a user friendly label for this credential"
        value={keyName}
        onChange={(e) => setKeyName(e.target.value)}
      />
    </div>,
    valid() ? createNewCredential : undefined,
    connecting
  );
}
