export const formatErrorMessage = (error) => {
  let code;
  if (error.extensions?.grpcCodeName) {
    code = error.extensions.grpcCodeName;
  } else {
    code = error.code;
  }
  switch (code) {
    case 'DataSourceUnavailable':
    case 1007:
      return 'External data service unavailable. Please try again later.';
    case 'GSheetAlreadyOwned':
    case 1008:
      return 'Google sheet already connected to another Numbrz account.';
    case 'TablesetSourceInvalid':
    case 1010:
      return 'Unable to connect. Please confirm the data source exists, and you have access.';
    case 'Oauth2AuthRejected':
    case 1012:
      return 'Oauth2 authorization expired, revoked or insufficient permission.';
    case 'Oauth2ExchangeFailed':
    case 1013:
      return 'Oauth2 Code Exchange failed.';
    case 'GoogleBackendError':
    case 1014:
      return 'A cell in your spreadsheet contains a data reference error.  All #REF! errors must be fixed in order to read your spreadsheet.';
    case 'BadRequest':
    case 400:
      return 'Cannot acquire credentials: Google: Refresh token is expired or access is revoked';
    case 'InternalError':
    case 500:
      return error.message;
    default:
      return 'Error';
  }
};

export const formatDataSourceLabel = (source, connectorName) => {
  if (connectorName === 'bigquery') return 'Google Big Query';
  if (connectorName === 'motherduck') return 'MotherDuck';
  if (source === 'QuickbooksOnline') return 'QuickBooks Online';

  return source ? source.replace(/([a-z](?=[A-Z]))/g, '$1 ') : source;
};

export const formatDataSourceJobs = (source = '') => {
  let srcLbl = source === 'QuickbooksOnline' ? 'QuickBooks Online' : source;
  srcLbl = srcLbl
    .replace('Spreadsheet', '')
    .replace(/([a-z](?=[A-Z]))/g, '$1 ');

  return srcLbl;
};

export function getConnectorDefaultParams(connectorName, activeCred) {
  switch (connectorName) {
    case 'gsheets':
      return [
        {
          name: 'spreadsheetURL',
          val: '',
        },
      ];
    case 'bigquery':
      return [
        {
          name: 'project',
          val: '',
        },
        {
          name: 'dataset',
          val: '',
        },
      ];
    case 'motherduck':
      return [
        {
          name: 'database',
          val: '',
        },
        {
          name: 'schema',
          val: '',
        },
      ];
    case 'quickbooks':
      return [
        {
          name: 'realmID',
          val: {
            stringV: activeCred?.providerParams?.realmID || '',
          },
        },
      ];
    case 'snowflake':
      return [
        {
          name: 'project',
          val: '',
        },
        {
          name: 'database',
          val: '',
        },
        {
          name: 'schema',
          val: '',
        },
        {
          name: 'warehouse',
          val: '',
        },
        {
          name: 'region',
          val: '',
        },
      ];
    case 'msexcel':
      return [
        {
          name: 'webURL',
          val: '',
        },
      ];
    case 'files':
      return [
        {
          name: 'filePath',
          val: '',
        },
        {
          name: 'fileSys',
          val: 'Dropbox',
        },
      ];
    case 'mongodb':
      return [
        {
          name: 'isNumbrz',
          val: true,
        },
      ];

    default:
      return [];
  }
}

export function formatConnectorParam(paramName, paramValue) {
  if (paramName === 'filePath') return formatFilePath(paramValue);
  if (paramName === 'realmID') return { stringV: paramValue };
  return paramValue;
}

export function formatParamValToPB(dataType, paramValue) {
  switch (dataType) {
    case 'String':
      return { stringV: paramValue };
    case 'Integer':
    case 'DateTime':
    case 'DateTimeNTZ':
    case 'Date':
      return { integerV: paramValue };
    case 'Decimal':
      return { decimalV: paramValue };
    case 'Boolean':
      return { boolV: paramValue };

    default:
      return { stringV: paramValue };
  }
}

export function formatFilePath(path) {
  // Dropbox connector
  // Folder path uses url, remove leading path to get to the folder
  if (path.includes('https://www.dropbox.com/home'))
    return decodeURI(path.replace('https://www.dropbox.com/home', ''));

  // Folder path provided, remove any leading/trailing spaces around slashes
  return path
    .split('/')
    .map((p) => p.trim())
    .join('/');
}

export function getConnectorParams(params = []) {
  return params.reduce((acc, cObj) => {
    acc[cObj.name] = cObj.val;
    return acc;
  }, {});
}

export function getConnectorProviderName(connectorName) {
  switch (connectorName) {
    case 'gsheets':
    case 'bigquery':
      return 'google';
    case 'files':
      return 'dropbox';
    case 'msexcel':
      return 'microsoft';
    default:
      return connectorName;
  }
}
