import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '<src>/apollo/client';

export const GetTablesetsPicker = gql`
  query GetTablesets {
    tablesets {
      tablesets {
        ID
        name
        comment
        source {
          ... on GoogleSpreadsheet {
            url
          }
          ... on ExcelSpreadsheet {
            shareURL
          }
          ... on DropboxFolder {
            path
          }
          ... on NumbrzDatabase {
            type
          }
          ... on QuickbooksOnline {
            type
          }
        }
        tables {
          ID
          name
          columns {
            type
            label
          }
        }
        connector {
          name
        }
      }
    }
  }
`;

export default function Connected(props) {
  const { data: { tablesets } = {}, loading } = useQuery(GetTablesetsPicker);

  return (
    <props.Comp
      {...props}
      loading={loading}
      tablesets={tablesets ? tablesets.tablesets : []}
    />
  );
}
