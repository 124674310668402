import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import fp from 'lodash/fp';
import styled from '@emotion/styled';

import Button from '<components>/Button';
import { useMutation, useQuery } from '<src>/apollo/client';

import Dialog from '<components>/Dialog';
import GoogleSpreadsheetPane from '../GoogleSpreadsheetPane';
import DropboxPane from '../DropboxPane';
import SnowflakeDatabasePane from '../SnowflakeDatabasePane';
import ExcelSpreadsheetPane from '../ExcelSpreadsheetPane';
import NumbrzDatabasePane from '../NumbrzDatabasePane';
import QuickbooksPane from '../QuickbooksPane';
import ConnectTablesPane from '../ConnectTablesPane';
import BigQueryPane from '../BigQueryPane';
import MotherDuckPane from '../MotherDuckPane';

import CredentialPickerPane from '../CredentialPickerPane';
import { Dropdown, Message } from 'semantic-ui-react';
import { DropdownWrapper } from '<src>/sections/data/styles';
import { GetCredentials } from '<sections>/account/queries';
import { CreateTableset, GetTableset } from '<sections>/data/queries';
import { hasExternalTables, tableLabel } from '../../TablesetPage/util';
import {
  getConnectorParams,
  getConnectorDefaultParams,
  getConnectorProviderName,
  formatConnectorParam,
} from '<sections>/data/utils';
import {
  FlexColumnContainer,
  StyledScrollbar,
} from '<src>/components/NumbrzPageComponents';
import ExcelAccessAgent from '<components>/ExcelAccessAgent';

const DialogFooter = styled(Dialog.Footer)`
  & > button {
    margin-left: 0;
    margin-right: 10px;
  }
  & > button:last-child {
    margin-right: 0;
  }
  display: flex;
`;

const StyledDialog = styled(Dialog)`
  min-width: 450px;
  height: 80%;
  width: 60%;
  overflow: auto;
  ${StyledScrollbar};
`;

const StyledDialogBody = styled(Dialog.Body)`
  padding-top: 10px;
  margin-bottom: 20px;
`;

const TableConnectWrapper = styled(FlexColumnContainer)`
  justify-content: flex-start;
  overflow: auto;
  margin-bottom: 20px;
  ${StyledScrollbar};
`;

export default function AddTablesetDialog({
  openTableset,
  visible,
  onDialogClose,
}) {
  const [activeTab, setActiveTab] = useState('gsheets');
  const [activeCred, setActiveCred] = useState();
  const [showConnectPane, setShowConnectPane] = useState();

  const [tablesetID, setTablesetID] = useState();
  const [tablesHdr, setTablesHdr] = useState('tables');
  const [tablesetSrc, setTablesetSrc] = useState();
  const [tablesetName, setTablesetName] = useState('');

  const [connectorMeta, setConnectorMeta] = useState({
    name: activeTab ? activeTab : 'gsheets',
    params: getConnectorDefaultParams(
      activeTab ? activeTab : 'gsheets',
      activeCred
    ),
  });

  const [creating, setCreating] = useState(false);
  const [errors, setErrors] = useState();

  const { data, loading: loadingCreds } = useQuery(GetCredentials, {
    variables: {},
  });

  const [createTableset] = useMutation(CreateTableset, {
    update: (cache, { data }) => {
      if (data.createTableset) {
        cache.writeQuery({
          query: GetTableset,
          variables: { ID: data.createTableset.ID },
          data: { tableset: data.createTableset },
        });
      }
    },
  });

  const credentials = useMemo(() => {
    if (loadingCreds) return [];
    let creds = fp.getOr([], 'listCredentials', data);

    if (activeTab === 'bigquery') {
      creds = creds.filter(
        (c) => c.providerName === 'google' && c.type === 'ServiceAcct'
      );
    } else if (activeTab === 'motherduck') {
      creds = creds.filter(
        (c) => c.providerName === 'motherDuck' && c.type === 'ServiceAcct'
      );
    } else {
      creds = creds.filter(
        (c) => c.providerName === getConnectorProviderName(activeTab)
      );
    }

    return creds;
  }, [loadingCreds, activeTab, data]);

  const onInputChange = useCallback(
    (userEvent) => {
      const {
        target: { name: paramName, value: paramValue },
      } = userEvent;

      const newParams = [...connectorMeta.params];
      const newParamValue = formatConnectorParam(paramName, paramValue);

      if (newParams.length === 0) {
        newParams.push({ name: paramName, val: newParamValue });
        setConnectorMeta({ ...connectorMeta, params: newParams });
      } else {
        const paramIdx = newParams.findIndex((p) => p.name === paramName);

        if (paramIdx >= 0) {
          newParams[paramIdx].val = newParamValue;
          setConnectorMeta({ ...connectorMeta, params: newParams });
        }
      }
    },
    [connectorMeta]
  );

  // if there is only one credential, make it default
  // if connector = bigquery, use only ServiceAccounts
  //
  useEffect(() => {
    if (
      credentials.length === 1 &&
      ((['gsheets', 'quickbooks', 'msexcel'].includes(activeTab) &&
        credentials[0].type === 'Oauth2') ||
        (activeTab === 'bigquery' && credentials[0].type === 'ServiceAcct') ||
        (activeTab === 'motherduck' && credentials[0].type === 'ServiceAcct') ||
        (activeTab === 'snowflake' && credentials[0].type === 'Password'))
    )
      setActiveCred(credentials[0]);
    if (
      activeTab === 'quickbooks' &&
      ((credentials[0] && !connectorMeta.params[0].val) ||
        connectorMeta.params[0].val?.stringV !==
          activeCred?.providerParams?.realmID)
    ) {
      onInputChange({
        target: {
          name: 'realmID',
          value: credentials[0]?.providerParams?.realmID,
        },
      });
    }
  }, [credentials, activeTab, onInputChange, connectorMeta.params, activeCred]);

  const onTablesetCreated = useCallback(
    (tableset, src) => {
      setActiveCred(undefined);
      setConnectorMeta({
        name: activeTab,
        params: getConnectorDefaultParams(activeTab),
      });
      const { source: tablesetSrc, ID } = tableset;
      setTablesetID(ID);
      setTablesetSrc(src);
      setTablesHdr(
        tableLabel(tableset.source, {
          plural: true,
          titleCase: true,
        })
      );

      hasExternalTables(tablesetSrc)
        ? setShowConnectPane(true)
        : openTableset(tableset.ID, src);
    },
    [activeTab, openTableset]
  );

  const onCreateTableset = useCallback(async () => {
    setErrors(null);
    setCreating(true);
    const { data, errors } = await createTableset({
      variables: {
        input: {
          credentialID: activeCred?.ID,
          connector: connectorMeta,
          ...(tablesetName && { name: tablesetName }),
        },
      },
    });

    if (!errors) {
      onTablesetCreated(data.createTableset);
    } else {
      setErrors(errors);
    }

    setCreating(false);
  }, [
    activeCred,
    createTableset,
    connectorMeta,
    onTablesetCreated,
    tablesetName,
  ]);

  const ConnectorOptions = [
    {
      key: 0,
      value: 'gsheets',
      text: 'Google Spreadsheet',
      content: <span>Google Spreadsheet</span>,
    },
    {
      key: 1,
      value: 'bigquery',
      text: 'Google BigQuery',
      content: <span>Google BigQuery</span>,
    },
    {
      key: 2,
      value: 'msexcel',
      text: 'Excel Workbook',
      content: <span>Excel Workbook</span>,
    },
    {
      key: 3,
      value: 'quickbooks',
      text: 'QuickBooks Online',
      content: <span>QuickBooks Online</span>,
    },
    {
      key: 4,
      value: 'snowflake',
      text: 'Snowflake Database',
      content: <span>Snowflake Database</span>,
    },
    {
      key: 5,
      value: 'motherduck',
      text: 'MotherDuck Database',
      content: <span>MotherDuck Database</span>,
    },
    {
      key: 6,
      value: 'mongodb',
      text: 'Numbrz Database',
      content: <span>Numbrz Database</span>,
    },
    {
      key: 7,
      value: 'files',
      text: 'Dropbox File',
      content: <span>Dropbox File</span>,
    },
  ];

  let CreateComp;

  switch (activeTab) {
    case 'gsheets':
      CreateComp = GoogleSpreadsheetPane;
      break;
    case 'bigquery':
      CreateComp = BigQueryPane;
      break;
    case 'snowflake':
      CreateComp = SnowflakeDatabasePane;
      break;
    case 'msexcel':
      CreateComp = ExcelSpreadsheetPane;
      break;
    case 'files':
      CreateComp = DropboxPane;
      break;
    case 'mongodb':
      CreateComp = NumbrzDatabasePane;
      break;
    case 'quickbooks':
      CreateComp = QuickbooksPane;
      break;
    case 'motherduck':
      CreateComp = MotherDuckPane;
      break;
    default:
      throw new TypeError(`Invalid tab: ${activeTab}`);
  }
  return (
    <StyledDialog
      visible={visible ? 1 : 0}
      onRequestClose={() => {
        setActiveCred(undefined);
        setActiveTab('gsheets');
        setConnectorMeta({
          name: data.value,
          params: getConnectorDefaultParams(data.value, activeCred),
        });
        onDialogClose();
      }}
    >
      <Dialog.Header>
        <Dialog.Headline>Connect data</Dialog.Headline>
        <Button.DialogClose
          onClick={() => {
            if (tablesetID) {
              openTableset(tablesetID, tablesetSrc);
              setTablesetID(null);
              setActiveCred(undefined);
            }
            onDialogClose();
          }}
        />
      </Dialog.Header>
      {errors && activeTab !== 'msexcel' ? (
        <Message error>
          <Message.Header>Error:</Message.Header>
          {errors[0].message}
        </Message>
      ) : errors ? (
        <ExcelAccessAgent
          sheetLabel="Excel Workbook"
          error={errors[0].message}
        />
      ) : null}

      {showConnectPane ? (
        <TableConnectWrapper>
          <Message info style={{ display: 'inline-table' }}>
            {`Data source was successfully connected. You can now add ${tablesHdr} to your connection to be used inside Numbrz.`}
          </Message>

          <ConnectTablesPane tablesetID={tablesetID} />
        </TableConnectWrapper>
      ) : (
        <DropdownWrapper>
          <Dropdown
            options={ConnectorOptions}
            placeholder="Select connector type"
            value={activeTab}
            onChange={(e, data) => {
              setActiveCred(undefined);
              setActiveTab(data.value);

              setConnectorMeta({
                name: data.value,
                params: getConnectorDefaultParams(data.value, activeCred),
              });
            }}
          />
        </DropdownWrapper>
      )}
      <CreateComp
        onInputChange={onInputChange}
        tablesetName={tablesetName}
        setTablesetName={setTablesetName}
        connectorParams={getConnectorParams(connectorMeta.params)}
        inputDisabled={creating}
      >
        {(body, invalidInput) => (
          <Fragment>
            {!showConnectPane && (
              <StyledDialogBody>
                {body}
                {activeTab !== 'mongodb' && (
                  <CredentialPickerPane
                    loading={loadingCreds}
                    credentials={credentials}
                    selectedItem={activeCred}
                    setActiveCred={setActiveCred}
                    connectorName={activeTab}
                    setActiveTab={setActiveTab}
                  />
                )}
              </StyledDialogBody>
            )}

            <DialogFooter style={{ position: 'sticky', top: '100%' }}>
              {showConnectPane ? (
                <Button
                  onClick={() => {
                    openTableset(tablesetID, tablesetSrc);
                    onDialogClose();
                  }}
                >
                  Finish
                </Button>
              ) : (
                <Button
                  onClick={onCreateTableset}
                  disabled={
                    creating ||
                    (!activeCred?.ID && activeTab !== 'mongodb') ||
                    invalidInput
                  }
                >
                  {creating ? 'Creating ...' : 'Next'}
                </Button>
              )}
            </DialogFooter>
          </Fragment>
        )}
      </CreateComp>
    </StyledDialog>
  );
}
