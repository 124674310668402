import React, { useState, useMemo, Fragment } from 'react';
import fp from 'lodash/fp';
import { getTableUsage } from '../utils';

import { useMutation } from '<src>/apollo/client';
import { SyncTableColumns } from '<sections>/data/queries';
import DatasetLinkedOutlinedIcon from '@mui/icons-material/DatasetLinkedOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import UsageChiclets from '../components/UsageChiclets';
import { Icon } from 'semantic-ui-react';

import { formatDataSourceLabel } from '<sections>/data/utils';
import { ErrorChiclet } from '<components>/NumbrzButtons';
import ErrorMessages from '<components>/ErrorMessages';
import JobTableMappings from './JobTableMappings';
import DataIssues from '../components/DataIssues';
import {
  TextIconBtn,
  StyledRouterLink,
  UsageWrapper,
  HdrToolbar,
  MappingSubhdr,
  MappingHdr,
  InternalIconLink,
  ExternalIconLink,
  InternalDataLink,
  LinkSeparator,
  JobTableLink,
  JTMappingContainer,
  InputIcon,
  OutputIcon,
  InputOutputIcon,
  JobTableSrcInfo,
  DataTypeLbl,
} from '../styles';
import { StageSummaryContainer, StepList } from '../JobStatus/styles';
import {
  FlexColumnContainer,
  FlexRowContainer,
} from '<components>/NumbrzPageComponents';

const DataTableName = StyledRouterLink.withComponent('span');

function resolveIssues(issues = []) {
  return issues.map((issue) => {
    if (issue.code === 'NotFound') {
      return {
        ...issue,
        message: issue.message || 'This job references a non-existent entity.',
      };
    }

    return issue;
  });
}

function DataTableLink({
  tableset,
  tableURL,
  mapping,
  table,
  textOnly = 'false',
}) {
  if (!table) {
    return (
      <DataTableName>{fp.getOr('', 'dataset.name', mapping)}</DataTableName>
    );
  }

  const LinkComp = textOnly ? JobTableLink : InternalIconLink;

  return (
    <LinkComp to={tableURL} fontSize="11px" title="Data source table">
      {textOnly && fp.getOr('', 'dataset.name', mapping)}
      {!textOnly && <DatasetLinkedOutlinedIcon />}
    </LinkComp>
  );
}

function DataTableInfo({ mapping, shareURL, tableURL, tableset }) {
  return (
    <MappingHdr>
      {mapping ? (
        <Fragment>
          <DataTableLink
            mapping={mapping}
            table={mapping.dataset}
            tableURL={tableURL}
            tableset={tableset}
            textOnly={true}
          />

          {shareURL && (
            <JobTableSrcInfo padding="0 0 0 0">
              <ExternalIconLink
                target="_blank"
                rel="noreferrer"
                href={shareURL}
                title="External source"
              >
                <LaunchIcon />
              </ExternalIconLink>
            </JobTableSrcInfo>
          )}
        </Fragment>
      ) : (
        <b style={{ marginLeft: '1px', height: '16px' }}>Not mapped</b>
      )}{' '}
    </MappingHdr>
  );
}

function TablesetInfo({ dataSrc, tableSrc }) {
  return dataSrc ? (
    <JobTableSrcInfo padding="0">
      <InternalDataLink
        to={`/data/${fp.getOr(
          undefined,
          'dataset.tableset.ID',
          dataSrc
        )}/tables`}
        fontSize="11px"
        title="Data connector"
      >
        {fp.getOr(null, 'dataset.tableset.name', dataSrc)}
      </InternalDataLink>
      <LinkSeparator>-</LinkSeparator>
      <DataTypeLbl>
        {formatDataSourceLabel(
          tableSrc,
          dataSrc?.dataset?.tableset?.connector?.name
        )}
      </DataTypeLbl>
    </JobTableSrcInfo>
  ) : null;
}

export default function Row({
  onMapTable,
  onMapField,
  projectID,
  flowID,
  onUnlinkTable,
  last,
  expanded,
  row,
  isBundle,
}) {
  const { mapping, jobTable = {}, dataTable = {}, dataSrc } = row;
  const issues = resolveIssues(mapping ? mapping.issues : []);

  const [open, setOpen] = useState(
    issues.length > 0 || expanded ? true : false
  );
  const [syncingTable = false, setSyncingTable] = useState();

  const [syncTableColumns] = useMutation(SyncTableColumns);

  const handleSyncTableCols = async () => {
    setSyncingTable(true);
    await syncTableColumns({ variables: { ID: dataTable.ID } });
    setSyncingTable(false);
  };

  const tableURL = `/models/${projectID}/flows/${flowID}/job-tables/${
    jobTable ? jobTable.ID : undefined
  }`;

  const tableset = fp.getOr(undefined, 'dataset.tableset', mapping);
  const tableSrc = fp.getOr(
    null,
    'dataset.tableset.source.__typename',
    mapping
  );

  const shareURL = fp.getOr(undefined, 'dataset.sourceURL', mapping);
  const localTableURL = dataTable
    ? tableset
      ? `/data/${tableset.ID}/tables/${dataTable.ID}`
      : `/datasets/${dataTable.ID}`
    : null;

  const usageTypes = getTableUsage(jobTable);
  const isInput = usageTypes.inputs > 0;
  const isOutput = usageTypes.outputs > 0;
  const isBoth = isInput && isOutput;

  const IconComp = useMemo(() => {
    if (isBoth) return <InputOutputIcon />;
    if (isInput) return <InputIcon />;
    if (isOutput) return <OutputIcon />;
    return null;
  }, [isBoth, isInput, isOutput]);

  return (
    <JTMappingContainer last={last} error={jobTable.required && !dataTable}>
      <StageSummaryContainer collapsed={!open}>
        <FlexColumnContainer alignItems="flex-start">
          <DataTableInfo
            mapping={mapping}
            shareURL={shareURL}
            tableset={tableset}
            tableURL={localTableURL}
          />

          <MappingSubhdr>
            <TablesetInfo dataSrc={dataSrc} tableSrc={tableSrc} />
            {!mapping && (
              <FlexRowContainer
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                {IconComp}
                <JobTableSrcInfo>
                  <BackupTableOutlinedIcon />
                  Model data:
                  {isBundle ? (
                    ` ${jobTable.name}`
                  ) : (
                    <InternalDataLink
                      className="large"
                      to={tableURL}
                      rel="noopener noreferrer"
                      title="Navigate to job table"
                      margin="0 0 0 2px"
                    >
                      {jobTable.name}
                    </InternalDataLink>
                  )}
                </JobTableSrcInfo>
              </FlexRowContainer>
            )}
          </MappingSubhdr>
        </FlexColumnContainer>

        <HdrToolbar>
          <FlexRowContainer justifyContent="flex-start">
            {dataTable && dataTable.state && !dataTable.state.ready && (
              <DataIssues table={dataTable} />
            )}
            {fp.getOr([], 'issues', mapping).length > 0 && (
              <ErrorChiclet marginRight="0" marginLeft="5px">{`${
                mapping.issues.length
              } ${
                mapping.issues.length === 1 ? 'Issue' : 'Issues'
              }`}</ErrorChiclet>
            )}
            <UsageChiclets
              usageTypes={usageTypes}
              mappingRequired={jobTable.required}
              mapping={mapping}
            />
          </FlexRowContainer>

          {dataSrc && (
            <HdrToolbar>
              <TextIconBtn
                margin="0 1px 0 0"
                onClick={() => {
                  setOpen(!open);
                }}
                expanded={open}
              >
                <DatasetOutlinedIcon />
                Details
                <Icon name={open ? 'angle up' : 'angle down'} />
              </TextIconBtn>
            </HdrToolbar>
          )}
          {!dataSrc && (
            <TextIconBtn
              iconSize="20px"
              fontSize="12px"
              margin="0 0 0 0"
              iconMargin="0 4px 0px 0"
              onClick={() => onMapTable(jobTable)}
            >
              <InsertLinkRoundedIcon />
              Select data
            </TextIconBtn>
          )}
        </HdrToolbar>
      </StageSummaryContainer>
      {open && (
        <StepList collapsed={!open}>
          <FlexColumnContainer justifyContent="center" padding="10px">
            <UsageWrapper>
              <ErrorMessages
                issues={[...issues]}
                message="There are issues in this job:"
                noShadow={false}
                margin="0px 0 10px 0"
              />

              <JobTableMappings
                dataTable={dataTable}
                dataTableLink={
                  <InternalDataLink
                    className="large"
                    to={localTableURL}
                    rel="noopener noreferrer"
                    title="My data"
                    margin="0 0 5px 1px"
                    fontSize="11px"
                    height="10px"
                  >
                    {dataTable.name}
                  </InternalDataLink>
                }
                flowTable={jobTable}
                flowTableLink={
                  isBundle ? (
                    <span
                      style={{
                        fontWeight: 'normal',
                        fontSize: '11px',
                        marginTop: '-5px',
                      }}
                    >
                      {jobTable.name}
                    </span>
                  ) : (
                    <InternalDataLink
                      className="large"
                      to={tableURL}
                      rel="noopener noreferrer"
                      title="Model data"
                      margin="0 0 5px 1px"
                      fontSize="11px"
                      height="10px"
                    >
                      {jobTable.name}
                    </InternalDataLink>
                  )
                }
                dataMapping={mapping}
                onFieldChange={onMapField}
                syncCols={handleSyncTableCols}
                syncing={syncingTable}
              />
              <TextIconBtn
                iconSize="19px"
                fontSize="12px"
                margin="15px 0 0 0"
                iconMargin="0 4px 0px 0"
                deleteMode={true}
                onClick={() => {
                  if (open) setOpen(false);
                  onUnlinkTable(mapping);
                }}
              >
                <LinkOffOutlinedIcon />
                Unmap
              </TextIconBtn>
            </UsageWrapper>
          </FlexColumnContainer>
        </StepList>
      )}
    </JTMappingContainer>
  );
}
