import React, { Fragment } from 'react';

import { isEmpty } from '<src>/utils/text';
import { StyledTextInput } from '<src>/sections/data/styles';

function isValid(database, schema) {
  if (isEmpty(database) || isEmpty(schema)) return false;

  return true;
}

export default function MotherDuckPane({
  children: renderBody,
  onInputChange,
  connectorParams,
  inputDisabled = false,
}) {
  const body = (
    <Fragment>
      <StyledTextInput
        //eslint-disable-next-line
        autoFocus={true}
        placeholder="The MotherDuck database name (Required)"
        name="database"
        onChange={onInputChange}
        disabled={inputDisabled}
        value={connectorParams.database}
      />
      <StyledTextInput
        //eslint-disable-next-line
        autoFocus={true}
        placeholder="The MotherDuck schema name (Required)"
        name="schema"
        onChange={onInputChange}
        disabled={inputDisabled}
        value={connectorParams.schema}
        style={{ marginTop: '10px' }}
      />
    </Fragment>
  );

  return renderBody(
    body,
    !isValid(connectorParams.database, connectorParams.schema)
  );
}
